// src/components/CookieConsent.js
import React, { useState, useEffect } from 'react';

function CookieConsent() {
  const [showConsent, setShowConsent] = useState(false);

  useEffect(() => {
    // Verifica se o usuário já aceitou os cookies
    const consentGiven = localStorage.getItem('cookieConsent');
    if (!consentGiven) {
      setShowConsent(true); // Exibe o consentimento se o usuário ainda não aceitou
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem('cookieConsent', 'true'); // Salva a aceitação no localStorage
    setShowConsent(false); // Esconde o consentimento
  };

  if (!showConsent) return null; // Não renderiza se já aceitou

  return (
    <div className="cookie-consent">
      <p>

      <span>Ao navegar neste site está a dar o seu acordo às <a class="pink_dog" href="https://www.fidelidade.pt//PT/Documents/Condicoes_Gerais_de_Utilizacao_Site-Fidelidade.pdf" target="_blank" wbo-id="0">Condições Gerais de Utilização.</a> Consulte também a nossa <a class="pink_dog" href="https://www.fidelidade.pt//PT/protecao-dados/Documents/Politica_de_Privacidade_e_Protecao_de_Dados_Pessoais–FIDELIDADE.pdf" target="_blank" wbo-id="1">Política de Privacidade e Proteção de Dados Pessoais</a> e <a class="pink_dog" href="https://www.fidelidade.pt//PT/protecao-dados/Documents/Politica_de_Cookies–FIDELIDADE.pdf" target="_blank" wbo-id="2">Política de Cookies</a></span>

       </p>
      <button onClick={acceptCookies} className="accept-button">
  Fechar
      </button>
    </div>
  );
}

export default CookieConsent;
