import React from 'react';
import arrowcta from '../src/assets/arrow-cta.svg';
import homeicon from '../src/assets/home.png';
import animal from '../src/assets/animal.png';
import messagem from '../src/assets/messagem.png';
import empresas from '../src/assets/empresas.png';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';

const scrollToTop = () => {
  scroll.scrollToTop();
};

function Service() {
  // Define o efeito de hover para aumentar o tamanho dos cartões
  const cardHoverStyle = 'transform transition-transform duration-300 ease-in-out hover:scale-105';

  return (
    <div className='bg-[#f0f0f0] py-12 px-10 lg:px-0'>
      <h1 className="text-5xl font-bold text-themered mb-2 text-center uppercase font-bebasneuepro">Serviços FIXO.PT</h1>
      <p className="text-md lg:text-lg text-[#212121] font-semibold mb-10 text-center">
        Descubra todos os serviços disponíveis no FIXO para que tenha mais tempo livre para si.
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 lg:max-w-6xl mx-auto">
        {/* Cards */}
        {[
          { icon: homeicon, title: 'CASA', desc: 'Engomaria e Lavandaria, Remodelação e Decoração, Limpeza, Instalação e Reparação, Jardinagem' },
          { icon: animal, title: 'Animais', desc: 'Banho a Cães, Tosquia a Cães' },
          { icon: messagem, title: 'Bem-estar', desc: 'Massagem, Manicure, Pedicure, Nutricionista, Personal Trainer' },
          { icon: empresas, title: 'Empresas', desc: 'Limpezas de escritórios, Limpeza de Bares, Limpeza de Restaurantes' }
        ].map((service, index) => (
          <div
            key={index}
            className={`bg-white p-6 rounded-lg shadow-lg relative text-center cursor-pointer ${cardHoverStyle}`}
          >
            <div className="w-[62px] h-[62px] absolute -top-4 right-2 font-bebasneuepro bg-themered text-white uppercase text-sm font-bold rounded-full text-center flex flex-col items-center justify-center leading-none">
              <span>Oferta</span> <span className='text-3xl leading-[0.7em]'>50%</span>
            </div>
            <img src={service.icon} alt={service.title} className="mx-auto mb-4" />
            <h2 className="text-xl font-bold text-gray-800 mb-2">{service.title}</h2>
            {/* Descrição sempre visível */}
            <p className="text-gray-600 pb-5 transition-opacity duration-300 ease-in-out">
              {service.desc}
            </p>
          </div>
        ))}
      </div>

      {/* Ícone de Font Awesome e Conteúdo */}
      <div className='flex justify-center pb-4 mt-4 lg:mt-12'>
        <button
          onClick={scrollToTop}
          className='flex items-center justify-between w-[250px] lg:w-[350px] bg-[#E31A10] hover:bg-[#C4160C] text-center rounded-full px-4 py-3 text-white font-bold font-sans text-2xl transition-transform transform hover:scale-105 shadow-lg'
          style={{ boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)' }}
        >
          <FontAwesomeIcon icon={faTag} size='lg' style={{ marginRight: '10px' }} />
          <span>RECEBER 50% DE DESCONTO NO FIXO.PT</span>
        </button>
      </div>
      <div className="text-center">
            {/*  
O fixo está presente: Aveiro, Braga, Lisboa, Porto, Setúbal*/}
    </div>
    </div>
  );
}

export default Service;
